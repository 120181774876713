import Vue from "vue";
import {Auth0Client} from "@auth0/auth0-spa-js";
import config from "../../auth_config.json";

import {provider} from "./provider.js";
import {onLogin, onLogout} from "../vue-apollo.js";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => {
  return instance;
}

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  let client = new Auth0Client({
    ...options,
    client_id: options.clientId,
    redirect_uri: redirectUri
  })

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: client,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser({
            scope: config.scope,
            audience: config.audience,
          });
          this.isAuthenticated = !!this.user;
          this.error = null;
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }

        onLogin(provider.defaultClient)
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          let resp = await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser({
            audience: config.audience,
            scope: config.scope,
          })
          this.isAuthenticated = true;
          this.error = null;
          return resp
        } catch (e) {
          this.error = e;
          console.error("Error handing auth", e)
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      logout(o) {
        const resp = this.auth0Client.logout(o);
        onLogout(provider.defaultClient)
        return resp
      }
    },
    async created() {
      try {
        await this.auth0Client.checkSession()

        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.handleRedirectCallback();
          this.error = null;

          onLogin(provider.defaultClient);

          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
        console.error("Auth error", e);
      } finally {
        this.loading = false;
        // Without this we end up in a race condition with whether the call in vue-apollo.js happens first or second
        this.token = await getInstance().getTokenSilently({
          audience: config.audience,
          scope: config.scope,
        });

        this.user = await this.auth0Client.getUser({
          scope: config.scope,
          audience: config.audience,
        });

        if (this.user.email) {
          const emailParts = this.user.email.split('@')
          this.user.isInternal = emailParts[1] === "sendheirloom.com" || this.user.email === "samsullivan09@gmail.com"
        } else {
          this.user.isInternal = false
        }

        this.isAuthenticated = !!this.user
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
