<template>
  <div class="mb-2">
    <a :href="vendorSalesDownloadUrl.href" target="_blank">Download Last 30 Days (.csv)</a>
  </div>
</template>

<style lang="scss" scoped>
div {
  text-align: right;
}
</style>

<script>
import VendorId from '../vendorId'

import DOWNLOAD_LINK from '../graphql/DownloadLink.gql'

export default {
  name: "DownloadLinks",

  data: () => {
    return {
      vendorSalesDownloadUrl: {
        href: ''
      }
    }
  },

  apollo: {
    vendorSalesDownloadUrl: {
      query: DOWNLOAD_LINK,
      variables () {
        if (!this.$auth.user || !this.$auth.user.email) {
          throw new Error("No logged in user")
        }

        return { vendorId: VendorId.get() }
      },
      skip () {
        return !this.$auth.user || !this.$auth.user.email
      },
      error(error) {
        console.error("Error loading download link", error)
        this.error = error.message
      }
    }
  },
}
</script>
