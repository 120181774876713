<script>
import Vue from 'vue'

function emailToVendorId(email) {
  const domainPart = email.split('@')[1]
  // Dara's email is a legacy addition
  if (domainPart === "uncommongoods.com" || email === "dabrams242@gmail.com")
    return 'uncommon-goods'
  if (domainPart === "thegrommet.com")
    return 'the-grommet'
  if (domainPart === "hammacher.com")
    return 'hammacher-schlemmer'
  if (domainPart === "lemondropgifts.com")
    return 'lemon-drop-gifts'
  if (domainPart === "sendheirloom.com" || email === "zackbloom@gmail.com")
    return 'heirloom'

  throw new Error("Email domain " + domainPart + " not matched to a vendor")
}

export default new Vue({
  data() {
    return {
      vendorId: localStorage.selectedVendorId || 'heirloom'
    }
  },

  methods: {
    set(value) {
      localStorage.selectedVendorId = this.vendorId = value
    },

    get() {
      if (!this.$auth.user || !this.$auth.user.email) {
        return this.vendorId || ''
      }

      if (this.$auth.user.isInternal) {
        return this.vendorId || 'heirloom'
      } else {
        return emailToVendorId(this.$auth.user.email)
      }
    }
  }
})
</script>
