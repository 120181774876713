<template>
  <div class="text-center hero">
    <p class="lead" v-if="!$auth.isAuthenticated && !$auth.loading">
      Please login above to begin managing orders.
    </p>
    <p class="lead" v-if="$auth.loading">
      Loading...
    </p>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>
