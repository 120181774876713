<template>
  <div class="Page-orders" v-if="$auth.isAuthenticated">
    <div v-if="error" class="alert alert-danger">
      <h3 class="alert-heading">Error</h3>
      <p>Please reload the page to try again.</p>
      <hr>
      <h5>Details</h5>
      <p class="small">{{ error }}</p>
    </div>

    <download-links v-if="!error" />

    <form @submit="saveSale" v-if="!error">
      <table class="w-100">
        <thead>
          <tr>
            <th />
            <th />
            <th class="pr-2">Date</th>
            <th class="pr-2">Order ID</th>
            <th class="pr-2">Customer Name</th>
            <th class="pr-2" v-if="vendorId.get() !== 'amazon'">Customer Email</th>
            <th class="pr-2">Quantity</th>
            <th class="pr-2">Duration</th>
            <th class="pr-2">Shipping Speed</th>
            <th class="pr-2">Gift Box</th>
            <th class="pr-2">Custom Front</th>
            <th class="pr-2">Custom Back</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
            <td>{{ (new Date).toLocaleDateString() }}</td>
            <td class="pr-2">
              <input v-model="newSale.externalOrderId" required class="form-control" style="width: 7em" tabindex=1 />
            </td>
            <td class="pr-2">
              <input v-model="newSale.customerFirstName" required class="w-100 form-control" tabindex=1 />
            </td>
            <td class="pr-2" v-if="vendorId.get() !== 'amazon'">
              <input v-model="newSale.customerEmail" required class="w-100 form-control" tabindex=1 />
            </td>
            <td class="pr-2">
              <select v-model="newSale.quantity" required class="w-100 custom-select" tabindex=1>
                <option v-for="n in 50" :key="n">{{ n }}</option>
              </select>
            </td>
            <td class="pr-2">
              <select v-model="newSale.duration" required class="w-100 custom-select" tabindex=1>
                <option value="10" default>10 min</option>
                <option value="20">20 min</option>
              </select>
            </td>
            <td class="pr-2" v-if="vendorId.get() === 'uncommon-goods'">
              <select v-model="newSale.shippingSpeed" required class="w-100 custom-select" tabindex=1>
                <option value="UG:economy">Economy</option>
                <option value="UG:standard">Standard</option>
                <option value="UG:expedited">Expedited</option>
                <option value="UG:express">Express</option>
                <option value="UG:perks">Perks</option>
              </select>
            </td>
            <td class="pr-2" v-if="vendorId.get() === 'etsy'">
              <select v-model="newSale.shippingSpeed" required class="w-100 custom-select" tabindex=1>
                <option value="standard" default>Standard</option>
                <option value="express">Express</option>
              </select>
            </td>
            <td class="pr-2" v-if="vendorId.get() !== 'uncommon-goods' && vendorId.get() !== 'etsy'">
              <select v-model="newSale.shippingSpeed" required class="w-100 custom-select" tabindex=1>
                <option value="economy" default>Economy</option>
                <option value="express">Express</option>
              </select>
            </td>
            <td class="pr-2">
              <input type="checkbox" v-model="newSale.hasGiftBox" tabindex=1 />
            </td>
            <td class="pr-2">
              <input type="checkbox" v-model="newSale.hasCustomCoverFront" tabindex=1 />
            </td>
            <td class="pr-2">
              <input type="checkbox" v-model="newSale.hasCustomCoverBack" tabindex=1 />
            </td>
            <td />
            <td><input type="submit" value="Save" class="form-control" tabindex=5 /></td>
          </tr>

          <tr v-for="sale in vendorSales"
              :key="sale.id"
              v-bind:class="{ cancelled: sale.cancelledAt }">
            <td class="text-right align-middle">
              <span v-if="sale.orders" class="row-icons">
                <a v-for="order in sale.orders"
                   :key="order.id"
                   :href="'https://send-heirloom-inc.myshopify.com/admin/orders/' + order.id"
                   target="_blank">
                  🌟
                </a>
              </span>
            </td>
            <td class="text-right align-middle">
              <span v-if="sale.orders" class="row-icons">
                <span v-for="order in sale.orders"
                  v-if="order.shippingLabels"
                  :key="order.id">
                  <a v-for="label in order.shippingLabels"
                    v-if="!label.voided"
                    :key="label.tracking_number"
                    :href="'https://t.17track.net/en#nums=' + label.tracking_number"
                    target="_blank">
                  ✉️
                  </a>
                </span>
              </span>
            </td>

            <td><span>{{ new Date(sale.createdAt).toLocaleDateString() }}</span></td>
            <td><span>{{ sale.externalOrderId }}</span></td>
            <td><span>{{ sale.customerFirstName }}</span></td>
            <td v-if="vendorId.get() !== 'amazon'"><span>{{ sale.customerEmail }}</span></td>
            <td><span>{{ sale.quantity }}</span></td>
            <td><span>{{ sale.duration }}</span></td>
            <td><span>{{ renderShippingSpeed(sale.shippingSpeed) }}</span></td>
            <td><input type="checkbox" disabled v-model="sale.hasGiftBox" /></td>
            <td><input type="checkbox" disabled v-model="sale.hasCustomCoverFront" /></td>
            <td><input type="checkbox" disabled v-model="sale.hasCustomCoverBack" /></td>
            <td>
              <form>
                <button v-if="!sale.cancelledAt" class="form-control btn-danger" @click="setCancellation($event, sale.id, true)">Cancel</button>
                <button v-if="sale.cancelledAt" class="form-control" @click="setCancellation($event, sale.id, false)">Uncancel</button>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</template>

<style lang="scss" scoped>
tr.cancelled {
  span, input {
    text-decoration: line-through;
  }
}
.row-icons {
  padding-right: 0.5em;
  white-space: nowrap;
  overflow: auto;
  display: block;
  max-width: 4em;
}
</style>

<script>
/* eslint-disable no-console */

import SALES_ALL from '../graphql/SalesAll.gql'
import SALES_CREATE from '../graphql/SalesCreate.gql'
import SALES_UPDATE_CANCELLATION from '../graphql/SalesUpdateCancellation.gql'

import VendorId from '../vendorId'
import DownloadLinks from './DownloadLinks'

export default {
  name: "HomeContent",

  components: {
    DownloadLinks,
  },

  methods: {
    renderShippingSpeed(speed) {
      if (!speed)
        return speed

      var speedPart = speed.split(":")[1]
      if (!speedPart)
        return speed

      return speedPart[0].toUpperCase() + speedPart.substring(1)
    },

    setCancellation(e, saleId, cancelled) {
      e.preventDefault()

      this.$apollo.mutate({
        mutation: SALES_UPDATE_CANCELLATION,
        variables: {
          saleId,
          cancelled,
        },

        update: (store, {data: {updateVendorSaleCancellation}}) => {
          let data = store.readQuery({query: SALES_ALL, variables: {vendorId: VendorId.get()}})
          for (let i=0; i < data.vendorSales.length; i++){
            if (data.vendorSales[i].id == saleId){
              data.vendorSales[i].cancelledAt = updateVendorSaleCancellation.cancelledAt
            }
          }
          store.writeQuery({query: SALES_ALL, variables: {vendorId: VendorId.get()}, data})
        },

        optimisticResponse: {
          __typename: 'Mutation',
          updateVendorSaleCancellation: {
            id: saleId,
            cancelledAt: (cancelled ? (new Date).toString() : null),
            __typename: 'VendorSale',
          }
        }
      }).catch((error) => {
        console.error('error saving cancellation', error)
      })
    },

    saveSale(e) {
      e.preventDefault()

      let createdBy = "unknown"
      if (this.$auth.user && this.$auth.user.email){
        // $auth.user is not always populated. This would be a great bug to fix.
        createdBy = this.$auth.user.email
      }

      let newSale = Object.assign(this.newSale, {
        vendorId: VendorId.get(),
        createdBy,
      })

      this.$apollo.mutate({
        mutation: SALES_CREATE,
        variables: {
          sale: this.newSale,
        },

        update: (store, {data: {registerVendorSale}}) => {
          let data = store.readQuery({query: SALES_ALL, variables: {vendorId: VendorId.get()}})
          data.vendorSales.unshift(registerVendorSale)
          store.writeQuery({query: SALES_ALL, variables: {vendorId: VendorId.get()}, data})
        },

        optimisticResponse: {
          __typename: 'Mutation',
          registerVendorSale: Object.assign(
            {
              id: "_new",
              createdAt: (new Date).toString(),
              deeplinkURL: 'Loading...',
              cancelledAt: null,
              __typename: 'VendorSale',
            },
            this.newSale,
          ),
        }
      }).then(() => {
        this.newSale = {duration: 10}
      }).catch((error) => {
        console.error('error saving', error)
        this.newSale = newSale
      })
    }
  },

  data: () => {
    return {
      newSale: {
        duration: 10,
        shippingSpeed: 'economy',
      },
      error: null,
      vendorId: VendorId,
    }
  },

  apollo: {
    vendorSales: {
      query: SALES_ALL,
      variables () {
        if (!this.$auth.user || !this.$auth.user.email) {
          throw new Error("No logged in user")
        }

        return { vendorId: VendorId.get() }
      },
      skip () {
        return !this.$auth.user || !this.$auth.user.email
      },
      error(error) {
        console.error("Error loading data", error)
        this.error = error.message
      }
    },
  },
}

</script>
