<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <div class="container-fluid" style="max-width:1400px">
      <error />
      <div class="mt-5">
        <router-view />
      </div>
    </div>
    <footer class="bg-light text-center p-3">
      <div class="logo"></div>
      <p>
        © 2022 Send Heirloom, Inc.
      </p>
    </footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";

export default {
  components: {
    NavBar,
    Error
  }
};
</script>
