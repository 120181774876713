<template>
  <div class="nav-container mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <div class="container">
        <div class="navbar-brand logo"></div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link" tabindex=-1>
                <img class="mb-3 app-logo" src="/logo.png" alt="Heirloom Logo" />
              </router-link>
            </li>
          </ul>

          <div v-if="$auth.isAuthenticated && $auth.user.isInternal" class="nav-nav mr-3">
            <select v-model="selectedVendorId" @change="changeVendorId()">
              <option value="heirloom">Internal Preorders</option>
              <option value="marketing">Marketing Comps</option>
              <option value="uncommon-goods">Uncommon Goods</option>
              <option value="amazon">Amazon</option>
              <option value="gma">GMA Deals &amp; Steals</option>
              <option value="cbs">CBS / Knocking</option>
              <option value="lemon-drop-gifts">Lemon Drop Gifts</option>
              <option value="etsy">Etsy</option>
            </select>
          </div>

          <ul class="navbar-nav d-none d-md-block">
            <li v-if="!$auth.isAuthenticated && !$auth.loading" class="nav-item">
              <button
                id="qsLoginBtn"
                class="btn btn-primary btn-margin"
                @click.prevent="login"
              >Login</button>
            </li>

            <li class="nav-item dropdown" v-if="$auth.isAuthenticated">
              {{ $auth.user && $auth.user.email }}
              <a
                class="nav-link dropdown-toggle d-inline"
                href="#"
                id="profileDropDown"
                data-toggle="dropdown"
              >
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
                  <font-awesome-icon class="mr-3" icon="power-off" />Log out
                </a>
              </div>
            </li>
          </ul>

          <ul class="navbar-nav d-md-none" v-if="!$auth.isAuthenticated && !$auth.loading">
            <button id="qsLoginBtn" class="btn btn-primary btn-block" @click="login">Log in</button>
          </ul>

          <ul
            id="mobileAuthNavBar"
            class="navbar-nav d-md-none d-flex"
            v-if="$auth.isAuthenticated"
          >
            <li>
              <font-awesome-icon icon="power-off" class="mr-3" />
              <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import authConfig from "../../auth_config.json";
import VendorId from '../vendorId'

export default {
  name: "NavBar",
  data() {
    return {
      selectedVendorId: VendorId.get()
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect({
        audience: authConfig.audience,
        scope: authConfig.scope,
      });
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    changeVendorId() {
      VendorId.set(this.selectedVendorId)
    },
  }
};
</script>

<style>
#mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
}
.app-logo {
  max-width: 10em;
}
</style>
